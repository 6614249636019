import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { trigger, transition, animate, style, state } from '@angular/animations'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ISliderItem } from './Islider-item';
import { WebcamImage, WebcamInitError } from 'ngx-webcam';
import { Observable, Subject } from 'rxjs';
import { CoreService } from '../../services/core.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';


@Component({
  selector: 'app-modal-prueba-vida',
  templateUrl: './modal-prueba-vida.component.html',
  styleUrls: ['./modal-prueba-vida.component.scss'],
  animations: [

    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateX(100%)' }),
        animate('200ms ease', style({ transform: 'translateX(0%)' }))
      ]),
      transition(':leave', [
        animate('200ms ease', style({ transform: 'translateX(-100%)' }))
      ])
    ])

  ]
})
export class ModalPruebaVidaComponent implements OnInit {
  @Input() items: ISliderItem[] = [];
  @Input() documentoId: number;
  @Input() pesoMaximoArchivo: number;
  @Input() titulo: string;
  @Input() descripcion: string;
  @Input() imagen: string;
  fileToUpload: string[] = [];
  DocumentoFirmaPruebaDeVidaId: number[] = []
  imgInfo;
  msjError: string = null;
  public showWebcam = false;
  public btnText: string;
  public errors: WebcamInitError[] = [];
  public webcamImage: WebcamImage = null;
  private trigger: Subject<void> = new Subject<void>();
  public currentPosition = 0;
  
  /*
  public videoOptions: MediaTrackConstraints = {
    facingMode: { exact: 'user' } // 'user' para cámara frontal, 'environment' para cámara trasera
  };
  */

  itemActual: ISliderItem;
  imageSrc: string | ArrayBuffer;
  constructor(public activeModal: NgbActiveModal, private coreService: CoreService, private sanitization: DomSanitizer,) { }

  ngOnInit(): void {
    this.imgInfo = 'data:image/jpg;base64,' + (this.sanitization.bypassSecurityTrustResourceUrl(this.imagen) as any).changingThisBreaksApplicationSecurity;
    this.btnText = "Empezar"
    let slide1: ISliderItem = {
      img: this.imgInfo,
    }
    this.items.unshift(slide1)
    this.itemActual = this.items[0]
    this.items.map((item, index) => {
      if (index > 0) {
        this.DocumentoFirmaPruebaDeVidaId.push(item.DocumentoFirmaPruebaDeVidaId)
      }
      item.id = index;
    })
    this.currentPosition = 0
  }

  getImg(img):SafeUrl {
    var imgSrc = 'data:image/jpeg;base64,' + 
    (this.sanitization.bypassSecurityTrustResourceUrl(img) as any).changingThisBreaksApplicationSecurity;
    return imgSrc;
  }

  public triggerSnapshot(): void {
    this.trigger.next();
  }

  public toggleWebcam(): void {
    this.msjError = null;
    this.showWebcam = !this.showWebcam;
  }

  public handleInitError(error: WebcamInitError): void {
    this.errors.push(error);
  }

  public handleImage(webcamImage: WebcamImage): void {
    this.webcamImage = webcamImage;
    const base64 = webcamImage.imageAsBase64
    const imageName = 'foto.jpeg';
    const imageBlob = this.dataURItoBlob(base64);
    let image = new File([imageBlob], imageName, { type: 'image/jpeg' });
    this.fileToUpload.push(base64)
    this.readFileToShow(image)
  }

  onFileDropped(e, item) {
    if (item.PermitirBuscarArchivos == true) {
      this.handleFileInput(e);
    }
  }
  
  dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/jpeg' });
    return blob;
  }

  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  handleFileInput(files: FileList) {
    let fileName = files[0].name;
    let size = files[0].size;
    if (size > this.pesoMaximoArchivo) {
      this.msjError = `Solo imágenes hasta ${this.pesoMaximoArchivo / 1048576} MB`
    } else {
      let idxDot = fileName.lastIndexOf(".") + 1;
      let extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
      if (extFile == "jpg" || extFile == "jpeg" || extFile == "png") {
        //TO DO
        this.msjError = null
        let image = files.item(0)
        const reader = new FileReader();
        reader.readAsDataURL(image);
        reader.onload = e => { this.imageSrc = reader.result; this.fileToUpload.push(reader.result.toString().split(',')[1]) };
      } else {
        this.msjError = "Solo se aceptan archivos .jpg, .jpeg o .png !"
      }
    }
  }

  readFileToShow(img) {
    const file = img
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = e => { this.imageSrc = reader.result; };
  }

  setNext() {
    this.btnText = "Siguiente"
    if (this.showWebcam) {
      this.showWebcam = false;
    }
    let nextPosition = this.currentPosition + 1;
    if (nextPosition >= this.items.length - 1) {
      this.btnText = "Finalizar"
    }
    if (nextPosition > this.items.length - 1) {
      let FotografiaAutoretrato = this.fileToUpload[0]
      let FotografiaGestual = this.fileToUpload[1]
      let DocumentoFirmaPruebaDeVidaId1 = this.DocumentoFirmaPruebaDeVidaId[0]
      let DocumentoFirmaPruebaDeVidaId2 = this.DocumentoFirmaPruebaDeVidaId[1]
      this.coreService.postPruebaVida(FotografiaAutoretrato, FotografiaGestual, DocumentoFirmaPruebaDeVidaId1, DocumentoFirmaPruebaDeVidaId2).subscribe(res => {
        if (res) {
          this.activeModal.close(true)
        }
      }, () => this.activeModal.close(false))
    } else {
      this.itemActual = null;
      this.imageSrc = null;
      this.currentPosition = nextPosition
    }
    setTimeout(() =>
    {
      this.itemActual = this.items[this.currentPosition]
      if(this.itemActual.PermitirActivarCamara == true && this.itemActual.PermitirBuscarArchivos == false){
        this.showWebcam = true;
      }
    }, 0)
  }

  deleteImg() {
    this.imageSrc = null;
    if (this.showWebcam) {
      this.showWebcam = false;
    }
    if(this.itemActual.PermitirActivarCamara == true && this.itemActual.PermitirBuscarArchivos == false){
      this.showWebcam = true;
    }
    this.fileToUpload.pop()

  }
}